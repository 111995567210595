class StringUtils {
  static isBlank(str: string): boolean {
    str = str.trim();
    return str.length ? false : true;
  }

  static toLowerCase(str: string): string {
    return str.toLowerCase();
  }
}

export default StringUtils;
