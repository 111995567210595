class LocalStorage {
  static STORE_KEYS = {
    SESSION_SHOW_VIEW: 'SESSION_SHOW_VIEW'
  };

  static set(key: string, value: string): void {
    window.sessionStorage.setItem(key, value);
  }

  static get(key: string): string | null {
    return window.sessionStorage.getItem(key) || null;
  }

  static remove(key: string): void {
    window.sessionStorage.removeItem(key);
  }

  static removeAll(): void {
    window.sessionStorage.clear();
  }
}

export default LocalStorage;
